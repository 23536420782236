<template>
  <div class="page_bg">
    <!-- <img class="bg-img" src="../../../public/img/login/login-bg.jpg" /> -->
    <van-nav-bar class="nav-bar" :title="curLang.loginTitle">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>

    <div
      style="
        background-color: rgba(0, 0, 0, 0.7);
        width: 90%;
        margin: 40px auto;
        margin-top: 200px;
        border-radius: 12px;
        padding: 40px 24px;
      "
    >
      <!-- 语言选择 -->
      <!-- <div @click="chooseLang()" style="text-align: right">
        <span style="color: #fff">{{ langName }}</span>
        <van-icon name="arrow-down" color="#fff" />
      </div> -->

      <label
        style="
          color: #ffcc99;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.8;
        "
        >{{ curLang.loginAccount }}</label
      >
      <div class="custom_input">
        <input
          v-model="username"
          type="text"
          :placeholder="curLang.loginEnterName"
        />
      </div>

      <label
        style="
          color: #ffcc99;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.8;
        "
        >{{ curLang.loginPwd }}</label
      >
      <div class="custom_input">
        <input
          v-model="password"
          :type="passwordType"
          :placeholder="curLang.loginEnterPwd"
        />
        <van-icon
          color="#aaa"
          :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
          @click="switchPasswordType"
        />
      </div>

      <div style="display: flex; align-items: center">
        <div class="custom_input">
          <input v-model="vcode" type="text" :placeholder="curLang.loginCode" />
        </div>
        <div
          class="custom_input"
          style="
            min-width: 70px;
            margin-left: auto;
            color: #ffcc99;
            padding: 0;
            text-align: center;
          "
          @click="genVCode()"
        >
          {{ curCode }}
        </div>
      </div>

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="font-size: 13px; color: #aaa">
          {{ curLang.loginForgotPwd }}
        </div>
        <div @click="toRegister()" style="font-size: 13px; color: #ffcc99">
          {{ curLang.loginTipLinkTo }}
        </div>
      </div>

      <div
        style="
          width: 80%;
          margin: 24px auto;
          border: 1px solid #ffcc99;
          border-radius: 22px;
          padding: 8px 0;
          text-align: center;
          color: #ffcc99;
        "
        @click="doLogin()"
      >
        {{ curLang.loginTitle }}
      </div>
    </div>
    <!-- <van-popup
      v-model="showLocale"
      round
      position="bottom"
      :style="{ height: '35%' }"
    >
      <van-picker
        show-toolbar
        :columns="locales"
        @confirm="onConfirm"
        @cancel="onCancel"
        :confirm-button-text="curLang.confirm"
        :cancel-button-text="curLang.cancel"
      />
    </van-popup> -->
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      curLocale: "", // 当前语言代码
      langName: "", // 当前语言
      showLocale: false, // 显示语言选择器
      username: "",
      password: this.inputValue,
      passwordType: "password",
      curCode: "", // 当前生成码
      vcode: "", // 验证码
    };
  },
  computed: {
    // 当前语言
    curLang() {
      console.log(`temp:`, this.$lang[this.curLocale]);
      return this.$lang[this.curLocale];
    },
    locales() {
      return [
        {
          value: "ko-KR",
          text: "한국어",
        },
        {
          value: "ja-JP",
          text: "日本语",
        },
        {
          value: "en-US",
          text: "English",
        },
      ];
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
    this.curLocale = localStorage.getItem("locale") || "ko-KR";
    this.curCode = this.curLang.loginCode;
    const temp = this.locales.filter((item) => item.value == this.curLocale);
    console.log(temp);
    this.langName = temp[0].text;
  },
  methods: {
    chooseLang() {
      this.showLocale = true;
    },
    onConfirm(value) {
      // this.langName = value.text;
      console.log(`value:`, value);
      localStorage.setItem(`locale`, value.value);
      this.showLocale = false;
      this.curLocale = localStorage.getItem("locale");
      const temp = this.locales.filter((item) => item.value == this.curLocale);
      console.log(temp);
      this.langName = temp[0].text;
      this.curCode = this.curLang.loginCode;
    },
    onCancel() {
      this.showLocale = false;
    },
    // 生成四个随机整数
    genVCode() {
      let randomNumber = "";
      for (let i = 0; i < 4; i++) {
        randomNumber += Math.floor(Math.random() * 10); // 生成0到9之间的随机数字
      }
      this.curCode = randomNumber;
    },

    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      return window.history.back();
    },
    toRegister() {
      this.$router.push("Register");
    },
    doLogin() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast(this.curLang.enterName);
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast(this.curLang.loginEnterPwd);
        return false;
      }

      if (this.vcode == "") {
        this.$toast(this.curLang.loginEnterCode);
        return false;
      }
      if (this.vcode !== this.curCode) {
        this.$toast(this.curLang.loginCodeError);
        return false;
      }

      this.$http({
        method: "get",
        data: { username: this.username, password: this.password },
        url: "member_login",
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(this.curLang.tipSuccess);
          localStorage.setItem("token", res.data.id);
          this.$router.push("Home");
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page_bg {
  background-image: url(../../../public/img/login/login-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.custom_input {
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 6px;
  height: 80px;
  line-height: 80px;
  padding-left: 24px;
  color: #fff;
  margin-bottom: 40px;
}
.custom_input input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 80%;
}
.custom_input input::placeholder {
  font-size: 24px;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel {
  font-size: 28px !important;
  color: #797878;
}

::v-deep .van-picker__confirm {
  font-size: 28px !important;
  color: #797878;
}
::v-deep .van-ellipsis {
  font-size: large;
}
// @import "../../assets/css/base.css";
</style>
