export default {
  confirm: `확인`,
  cancel: `취소`,

  loginTitle: `로그인`,
  loginAccount: `계정`,
  loginEnterName: `계정을 입력해 주세요`,
  loginPwd: `비밀번호`,
  loginEnterPwd: `비밀번호를 입력해주세요`,
  loginCode: `인증코드`,
  loginForgotPwd: `비밀번호를 잊으셨습니까?`,
  loginTipSuccess: `로그인 성공`,
  loginTipLinkTo: `회원가입`,
  loginEnterCode: `인증번호를 입력해주세요`,
  loginCodeError: `인증코드 오류`,

  // register
  registerTitle: `회원가입`,
  registerAccount: `계정`,
  registerEnterName: `아이디를 입력하세요`,
  registerPwd: `비밀번호`,
  registerEnterPwd: `비밀번호를 입력해주세요`,
  registerPwd2: `비밀번호 확인`,
  registerEnterPwd2: `비밀번호 확인`,
  inviteCode: `초대코드`,
  enterInviteCode: `초대 코드를 입력하세요`,
  registerCode: `인증코드`,
  registerTipPwd: `두 번 입력한 비밀번호가 일치하지 않습니다.`,
  registerEnterCode: `인증번호를 입력해주세요`,
  registerCodeError: `인증코드 오류`,
};
