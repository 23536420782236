export default {
  confirm: `確認`,
  cancel: `キャンセル`,

  loginTitle: `ログイン`,
  loginAccount: `アカウント`,
  loginEnterName: `アカウントを入力してください`,
  loginPwd: `パスワード`,
  loginEnterPwd: `パスワードを入力してください`,
  loginCode: `コード`,
  loginForgotPwd: `パスワードを忘れましたか？`,
  loginTipSuccess: `ログイン成功`,
  loginTipLinkTo: `サインアップ`,
  loginEnterCode: `認証番号を入力してください`,
  loginCodeError: `認証コードエラー`,
};
