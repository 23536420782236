<template>
  <div class="home-container">
    <div style="padding: 10px 20px; display: flex; align-items: center">
      <div style="flex: 0 0 20%">
        <img
          src="../../assets/logo.png"
          style="width: 40px; height: auto"
        />
      </div>
      <div style="flex: 1">
        <!-- <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-around;
          "
        >
          <div
            style="
              color: #ffcc99;
              font-size: 16px;
              font-weight: 700;
              padding: 4px 12px;
            "
            :style="{
              borderBottom: `3px solid ${
                curTab == 0 ? '#ffcc99' : 'transparent'
              }`,
            }"
            @click="changeTab(0)"
          >
            추천
          </div>
          <div
            style="
              color: #ffcc99;
              font-size: 16px;
              font-weight: 700;
              padding: 4px 12px;
            "
            :style="{
              borderBottom: `3px solid ${
                curTab == 1 ? '#ffcc99' : 'transparent'
              }`,
            }"
            @click="changeTab(1)"
          >
            비디오
          </div>
        </div> -->
      </div>
      <div style="flex: 0 0 20%"></div>
    </div>

    <!-- <template v-if="curTab == 0"> -->
    <div class="home-scroll" style="margin-top: 100px">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div
        class="notice-bar"
        style="
          background-color: #1b1b1b;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 16px;
        "
      >
        <van-notice-bar
          class="notice-swipe"
          left-icon="bullhorn-o"
          background="#333333"
          color="#FFCC99"
          text="밤의 귀족들"
        />
        <!-- :text="this.notice" -->
        <!-- <div class="linear-gradient"></div> -->
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>이벤트 투표</span>
          </div>
          <div @click="gotoMenu('/Game')">
            <span>더 보기</span>
            <van-icon name="arrow" color="#FFCC99" />
          </div>
        </div>
        <div class="hot-items-div" style="padding: 0 20px">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            "
          >
            <div
              style="flex: 0 0 50%"
              v-for="(v, key) in gameitem"
              :key="key"
              @click="toLottery(v.key, v.id)"
            >
              <div
                style="
                  background-color: #1b1b1b;
                  margin: 6px;
                  border: 1px solid #d0b59a;
                  border-radius: 6px;
                  display: flex;
                  align-items: center;
                  padding: 0 4px;
                "
              >
                <span
                  style="
                    text-align: center;
                    font-size: 13px;
                    padding-right: 20px;
                  "
                  >{{ v.name }}</span
                >
                <div style="margin-left: auto">
                  <van-image style="width: 50px; height: 50px" :src="v.ico">
                    <template v-slot:loading>
                      <van-loading type="circular" />
                    </template>
                  </van-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="Success"
      >
        <template #pulling> Scroll down to refresh </template>
        <template #loosing> Scroll down to refresh </template>
        <template #loading> loading </template>
        <div class="hot-recommend">
          <div class="hot-title-div">
            <div>
              <span>인기 평가</span>
            </div>
            <div>
              <span @click="gotoMenu('/Video')">더 보기</span>
              <van-icon name="arrow" color="#FFCC99" />
            </div>
          </div>
          <div
            class="movie_list_0"
            style="display: flex; align-items: center; justify-content: center"
          >
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v, key) in movielist_0" :key="key">
                <van-image
                  class="movie_cover"
                  @click="toPlayVideo(v.id)"
                  round
                  :src="v.cover"
                >
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png" />
                <img class="hot" v-if="key === 1" src="/img/home/no2.png" />
                <img class="hot" v-if="key === 2" src="/img/home/no3.png" />
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{ v.vod_name_vie }}</span>
                    <div class="van-count-down">{{ v.time }}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="hot-title-div">
            <div>
              <span>인기 조언</span>
            </div>
            <div @click="gotoMenu('/Video')">
              <span>더 보기</span>
              <van-icon name="arrow" color="#FFCC99" />
            </div>
          </div>
          <div class="movie_list_1">
            <div
              class="movie-list-item"
              v-for="(v, key) in movielist_1"
              :key="key"
              @click="toPlayVideo(v.id)"
            >
              <van-image class="cover_img" round :src="v.vod_pic">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
              <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{ v.vod_name_vie }}</span>
                  <span>chơi:{{ v.count }}</span>
                </div>
              </div>
            </div>
            <div class="hot-recommend-more" @click="gotoMenu('/Video')">
              더 보기
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <!-- </template>
    <template v-if="curTab == 1">
      <div style="min-height: 100vh">
        <TabVideo />
      </div>
    </template> -->
  </div>
</template>

<script>
// import TabVideo from "./TabVideo.vue";
export default {
  // components: { TabVideo },
  data() {
    return {
      // curTab: 0,

      notice: "",
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
    };
  },
  methods: {
    // changeTab(val) {
    //   this.curTab = val;
    // },

    gotoMenu(router) {
      this.$router.replace(router);
    },
    toLottery(key, id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/Lottery?key=" + key + "&id=" + id });
      }
    },
    toPlayVideo(id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/PlayVideo?id=" + id });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("성공");
      }, 500);
    },
    getVideoList() {
      this.$http({
        method: "get",
        data: { id: 0, page: 0 },
        url: "video_list",
      }).then((res) => {
        console.log(`???`, res.data.data);
        this.getMovieList_1(res.data.data); //获取首页视频1
      });
    },
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.basicData = res.data;
        console.log("3", res);
        this.getNotice(this.basicData); //获取公告
        this.getBanner(this.basicData); //获取banner
        this.getGameItem(); //获取首页游戏列表
        this.getMovieList_0(this.basicData); //获取首页视频0
      });
    },

    getNotice(data) {
      console.log("data===🚀===>", data);
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: "get",
        url: "lottery_hot",
      }).then((res) => {
        console.log(`?`, res.data);
        res.data.forEach((item, index) => {
          item.name = `투표 ${index + 1}`;
        });
        this.gameitem = res.data;
      });
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0;
    },
    getMovieList_1(data) {
      this.movielist_1 = data;
    },
    getBanner(data) {
      this.banners = data.banners;
    },
  },
  mounted() {},
  created() {
    this.getBasicConfig();
    this.getVideoList();
  },
};
</script>

<style lang="less" scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;
.folt {
  // overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  width: 170px;
}
// .linear-bg {
//   height: 200px;
//   background: linear-gradient(90deg, #1b1b1b, #1b1b1b);
// }
.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1b1b1b;
}
.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(126, 86, 120, 0),
    rgb(230, 195, 161),
    rgba(126, 86, 120, 0)
  );
}
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}
.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner {
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #1b1b1b;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .van-grid {
  justify-content: space-between;
}
.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game {
  width: 100%;
  height: 100%;
}
.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div > div:first-child {
  width: 330px;
}
.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 20px;
  color: #ffcc99;
}
.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #ffcc99;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #ffcc99;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 25px;
  color: #ffcc99;
}
.hot-title-div > div:first-child span:before {
  content: "";
  display: block;
  width: 7px;
  height: 28px;
  background-color: #1b1b1b;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div {
  margin-top: -3px;
}
.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #ffcc99;
}
.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #1b1b1b;
}
.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px;
}
.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_0 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span {
  font-size: 30px;
}
.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  height: 320px;
}
.home-scroll {
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;
}
.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_1 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}

.hot-items-div .ev_custom_item .van-grid-item__content {
  background-color: #1b1b1b !important;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
</style>
