export default {
  confirm: `Confirm`,
  cancel: `Cancel`,

  loginTitle: `Login`,
  loginAccount: `Account`,
  loginEnterName: `Please enter your account`,
  loginPwd: `Password`,
  loginEnterPwd: `Please enter your password`,
  loginCode: `Code`,
  loginForgotPwd: `Forgot your password?`,
  loginTipSuccess: `Login successful`,
  loginTipLinkTo: `Sign up`,
  loginEnterCode: `Please enter the verification code`,
  loginCodeError: `Verification code error`,
};
